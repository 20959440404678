<template>
  <v-container fluid>
    <Breadcrumbs />

    <v-container
      v-if="!firstTimeDispo && dispoList.length <= 0"
      fluid
      class="whiteBackGround"
    >
      <first-page
        :img-src="imgSrc"
        :dialog-name.sync="firstTimeDispo"
        :points="points"
        :headline="headline"
      ></first-page>
    </v-container>

    <v-row v-if="firstTimeDispo" class="pa-0 ma-0">
      <v-flex xs12 sm12 md12>
        <v-card flat tile>
          <v-card-text>
            <v-row>
              <!-- for input-->
              <v-col cols="12" sm="4">
                <v-card class="fill-height secondarygrad rounded">
                  <v-card-title class="ma-0 py-2 px-4">
                    <h5
                      class="secondary--text text--lighten-2 font-weight-medium"
                    >
                      Add New
                    </h5>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="primary"
                          style="opacity: 0.7"
                          dark
                          v-bind="attrs"
                          v-on="on"
                        >
                          info_outline
                        </v-icon>
                      </template>
                      <span>Create your own disposition/call status</span>
                    </v-tooltip>
                  </v-card-title>
                  <v-card-text>
                    <v-text-field
                      label="Name Of Status"
                      autofocus
                      :value="dispo.title.toUpperCase()"
                      :rules="rulesDisponame"
                      @input="dispo.title = $event.toUpperCase()"
                    >
                    </v-text-field>
                    <v-textarea
                      v-model="dispo.desc"
                      label="Description"
                      placeholder="(optional)"
                    >
                    </v-textarea>

                    <v-flex
                      xs12
                      sm12
                      md12
                      style="text-align: center !important"
                    >
                      <div align="center">
                        <v-color-picker
                          v-model="dispo.hex"
                          hide-mode-switch
                          mode="hexa"
                          class="mx-auto transparent widthCustom"
                          style="max-width: 100% !important"
                        >
                        </v-color-picker>
                      </div>
                    </v-flex>
                    <v-btn
                      fill
                      block
                      color="primarygrad white--text rounded"
                      @click="add"
                    >
                      <v-icon left :size="23">add_circle</v-icon> Add
                    </v-btn>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" sm="8">
                <v-card flat class="fill-height">
                  <v-card-title class="ma-0 py-2 px-4">
                    <h5
                      class="secondary--text text--lighten-2 font-weight-medium"
                    >
                      Status
                    </h5>
                  </v-card-title>
                  <v-card-text>
                    <v-row v-if="preloader" style="margin: 0px">
                      <v-card-text style="text-align: center !important">
                        <v-progress-circular
                          :width="3"
                          color="green"
                          indeterminate
                        ></v-progress-circular>
                      </v-card-text>
                    </v-row>
                    <v-row v-else-if="dispoList.length > 0" style="margin: 0px">
                      <v-flex
                        v-for="dispo in dispoList.filter((d) => {
                          return !d.system;
                        })"
                        :key="dispo._id"
                        xs12
                        sm6
                        md6
                        style="padding: 5px"
                      >
                        <v-card flat class="rounded inverted">
                          <v-card-title>
                            <v-icon :color="dispo.hex"
                              >fiber_manual_record</v-icon
                            >
                            <h6 class="font-weight-medium secondary--text pl-3">
                              {{ dispo.title }}
                            </h6>
                            <v-spacer></v-spacer>
                            <v-menu
                              origin="center center"
                              :close-on-content-click="false"
                              transition="scale-transition"
                            >
                              <template v-slot:activator="{ on }">
                                <v-btn small text fab v-on="on">
                                  <v-icon>more_vert</v-icon>
                                </v-btn>
                              </template>
                              <v-list>
                                <v-list-item @click="dispoEdit(dispo)">
                                  <v-list-item-title>Edit</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="del(dispo)">
                                  <v-list-item-title>Delete</v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-card-title>
                        </v-card>
                      </v-flex>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-row>

    <v-dialog v-model="dialogDispoEdit" width="450">
      <v-card>
        <v-card-title class="primary white--text"> Edit Status </v-card-title>

        <v-card-text style="padding-top: 25px; padding-bottom: 25px">
          <v-flex xs12 sm12 md12>
            <v-text-field
              v-model="dispoEditedItem.title"
              label="Name"
              :rules="rulesDisponame"
              :value="dispoEditedItem.title.toUpperCase()"
              @input="dispoEditedItem.title = $event.toUpperCase()"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 sm12 md12>
            <v-textarea
              v-model="dispoEditedItem.desc"
              label="Description"
              placeholder="(optional)"
            ></v-textarea>
          </v-flex>

          <v-flex xs12 sm12 md12 style="text-align: center !important">
            <v-color-picker
              v-model="dispoEditedItem.hex"
              hide-mode-switch
              mode="hexa"
              class="mx-auto"
            ></v-color-picker>
          </v-flex>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="error" @click="dialogDispoEdit = false"
            >Cancel</v-btn
          >
          <v-btn color="primary" @click="updateDispoName"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
const _ = require("lodash");
import fb from "../js/firebase";
import validator from "../js/validator.js";
import Breadcrumbs from "../components/breadcrumbs.vue";
import firstPage from "@/components/firstPage.vue";

export default {
  components: {
    Breadcrumbs,
    firstPage,
  },
  data() {
    return {
      firstTimeDispo: false,
      headline: "Custom Dispositions for Powerful Call Analysis",
      points: [
        "Customize Your Call Workflow: Create custom call statuses, dispositions, and feedback options that perfectly align with your unique sales process and telecalling team requirements.",
        "Deeper Data Insights: Gain a clearer understanding of your call performance with granular data filtering based on your custom dispositions.",
        "Actionable Reporting & Export: Generate comprehensive reports and easily export filtered data for further analysis.",
      ],
      dialogDispoEdit: false,
      dispoEditedItem: {
        title: "",
        desc: "",
      },
      preloader: false,
      dispo: {
        title: "",
        hex: "",
        desc: "",
      },
      rulesDisponame: [
        (v) =>
          /^[\w\s]+$/.test(v) ||
          "Maximum Seventeen characters & Must Not Contain Special Characters Or Space",
      ],
    };
  },
  mounted() {
    console.log("dispoList ..........", this.dispoList);

    if (this.dispoList.length > 0) {
      this.firstTimeDispo = true;
    } else {
      this.firstTimeDispo = false;
    }
  },
  computed: {
    dispoList(v) {
      return this.$store.getters.dispoList;
    },
  },
  watch: {
    dispoList() {
      this.preloader = false;
    },
  },
  created() {
    console.log("dispoList ..........", this.dispoList);
  },

  methods: {
    dispoEdit(item) {
      this.dispoEditedItem = item;
      this.dialogDispoEdit = true;
    },
    updateDispoName() {
      const self = this;

      // if (
      //   !/^\w+$/.test(this.dispoEditedItem.title) ||
      //   this.dispoEditedItem.title.length > 15
      // ) {
      //   return this.$swal({
      //     type: "warning",
      //     text:
      //       "Maximum Fifteen characters & Must Not Contain Special Characters Or Space",
      //   });
      // }

      if (this.dispoEditedItem.title == "") {
        this.$swal({
          type: "error",
          text: "Please add a name",
        });
      }

      if (
        this.dispoEditedItem.title.toUpperCase().trim() == "NEW" ||
        this.dispoEditedItem.title.toUpperCase().trim() == "SKIP" ||
        this.dispoEditedItem.title.toUpperCase().trim() == "DNC"
      ) {
        this.$swal({
          type: "error",
          text: "Please change the disposition name",
        });

        return;
      }

      if (
        !/^[\w\s]+$/.test(this.dispoEditedItem.title) ||
        this.dispoEditedItem.title.length > 17
      ) {
        return this.$swal({
          type: "warning",
          text:
            "Maximum Senenteen characters & Must Not Contain Special Characters",
        });
      } else {
        self.$http
          .patch(
            `${this.$store.state.ENDPOINT}/dispos/${self.dispoEditedItem.id}`,
            self.dispoEditedItem
          )
          .then(
            (response) => {
              self.dialogDispoEdit = false;
              self.$store.dispatch("getDispos", {
                companyId: this.$store.getters.user.companyId,
              });
            },
            (error) => {
              self.$swal({
                type: "warning",
                text: "Error occured. Please try again.",
              });
            }
          );
      }
    },

    add() {
      if (this.dispo.title == "") {
        console.log("** empty title **");
        return;
      }

      if (
        this.dispo.title.toUpperCase().trim() == "NEW" ||
        this.dispo.title.toUpperCase().trim() == "SKIP" ||
        this.dispo.title.toUpperCase().trim() == "DNC"
      ) {
        this.$swal({
          type: "error",
          text: "Please change the disposition name",
        });

        return;
      }

      if (!/^[\w\s]+$/.test(this.dispo.title) || this.dispo.title.length > 17) {
        return this.$swal({
          type: "warning",
          text:
            "Maximum Seventeen characters & Must Not Contain Special Characters",
        });
      } else {
        this.dispo.title = this.dispo.title.toUpperCase().trim();
        this.preloader = true;
        this.dispo.companyId = this.$store.getters.user.companyId;

        this.$http
          .post(`${this.$store.state.ENDPOINT}/dispos`, this.dispo)
          .then((response) => {
            this.dispo = {};
            this.dispo.title = "";
            this.dispo.hex = "";
            this.dispo.desc = "";
            this.$store.dispatch("getDispos", {
              companyId: this.$store.getters.user.companyId,
            });
            fb.log("create_dispo");

            return this.$swal({
              type: "success",
              text:
                "Please inform all app users to logout and login, to see the new changes.",
            });
          })
          .catch((e) => {
            this.preloader = false;
            this.$swal({
              type: "error",
              text: "Sorry, same status is already present",
            });
          });
      }
    },
    del(item) {
      this.preloader = true;

      this.$swal
        .fire({
          title: "Are you sure?",
          text: "Contacts with this status will become NEW",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "cancel",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.value) {
            this.$http
              .post(`${this.$store.state.ENDPOINT}/dispos/remove`, {
                dispo: item,
              })
              .then((response) => {
                this.preloader = false;
                this.$store.dispatch("getDispos", {
                  companyId: this.$store.getters.user.companyId,
                });
                fb.log("delete_dispo");
                return this.$swal({
                  type: "success",
                  text:
                    "Please inform all app users to logout and login, to see the new changes.",
                });
              })
              .catch((e) => {
                this.preloader = false;
                console.log(e);
              });
          }
          this.preloader = false;
        });
    },
  },
};
</script>

<style scoped>
.widthCustom {
  max-width: 600px !important;
}

.v-color-picker__canvas {
  width: 600px !important;
}
</style>
